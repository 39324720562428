import React, { useState } from 'react'
import Layout from '../components/layout'
import SEO from "../components/seo";
import { useStaticQuery, graphql, Link } from 'gatsby';
import PageHeader from '../components/pageHeader';
import { useLunr } from 'react-lunr'
import ProductTile from '../components/productTile';

const query = graphql`
{
  localSearchPages {
    index
    store
  }
  products: allStrapiProduct (filter: {publishedAt: {gt: "2020-01-17T13:26:54.386Z"}}) {
    nodes {
      slug
      name
      id
      bodyDescription
      category {
        name
      }
      images {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
  allStrapiCategory {
    edges {
      node {
        name
        slug
      }
    }
  }
}
`

export default function CatalogPage() {
  const data = useStaticQuery(query);

  const index = data.localSearchPages.index
  const store = data.localSearchPages.store
  const [searchQuery, setSearchQuery] = useState(null)
  const results = useLunr(searchQuery, index, store) // use lunr engine to search

  const products = searchQuery ? results : data.products.nodes
  const categories = data.allStrapiCategory.edges

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Live the Dream | Inspiration for Home Improvement"
      />
      <PageHeader
        title="Inspiration"
        subTitle="Check out our catalog and get inspiration for some creative & innovative design ideas for your door, wardrobe, and other furniture. Kick-start your build with us!"
      />
      <div className="container mx-auto">
        <div className="w-full lg:w-3/4 mx-auto flex flex-col gap-4 lg:gap-0 lg:flex-row items-start justify-center">
          <div className="w-full lg:w-1/2 flex gap-y-2 gap-x-4  flex-wrap">
            {categories.map(category => {
              return (
                <Link
                  to={category.node.slug}
                  activeClassName=""
                  className="text-center py-2 md:py-1 w-auto px-2 mt-2 text-xs rounded-full border  border-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white focus:outline-none focus:ring-0 active:bg-primary active:text-white active:shadow-lg transition duration-150 ease-in-out "
                >
                  {category.node.name}
                </Link>
              )
            })}
            <Link
              to="/inspiration"
              activeClassName="bg-primary text-white"
              className="text-center py-2 md:py-1 w-[93px] mt-2 text-xs rounded-full border border-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white focus:outline-none focus:ring-0 active:bg-primary active:text-white active:shadow-lg transition duration-150 ease-in-out "
            >
              All
            </Link>
            <Link
              to="/saved"
              activeClassName="bg-primary text-white"
              className="text-center py-2 md:py-1 w-[93px] mt-2 text-xs rounded-full border border-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white focus:outline-none focus:ring-0 active:bg-primary active:text-white active:shadow-lg transition duration-150 ease-in-out "
            >
              Liked
            </Link>
          </div>
          <input
            type="text"
            className='w-full lg:w-1/2 rounded-md border border-primary py-2 px-4 bg-[#C4C4C4] focus:outline-none'
            placeholder='Search'
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="my-6 w-full grid lg:grid-cols-3 gap-6 lg:gap-12">
          {products.map(product => <ProductTile product={product} />)}
        </div>
      </div>
    </Layout>
  )
}
